import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import { CCDialogSimple, CCIconButton, CCTypography } from "styles/components";

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    position: "relative",
    height: 36,
    backgroundColor: theme.palette.background.dialogHeader,
    paddingLeft: 20
  },
  content: {
    maxHeight: "calc(100vh - 170px)",
    overflowY: "auto"
  },
  actions: {
    padding: "10px 12px",
    borderTop: `1px solid ${theme.palette.border.main}`,
    "& > div:first-child": {
      flex: 1
    },
    "& > div:not(:first-child)": {
      marginLeft: 0
    }
  },
  icon__btn: {
    position: "absolute",
    color: "#fff",
    right: 0,
    top: 0,
    margin: 0
  },
  hidden: { display: "none" }
}));

const Dialog = props => {
  const classes = useStyles();
  const {
    open,
    onClose,
    onCloseButton,
    isHeader,
    title,
    contents,
    classes: customClasses,
    ...any
  } = props;

  return (
    <CCDialogSimple
      open={open}
      isHeader={false}
      onClose={onClose}
      contents={
        <>
          <Box className={classes.title}>
            <CCTypography
              variant="h3"
              style={{ color: "#fff", lineHeight: "36px" }}
            >
              {title}
            </CCTypography>
            <CCIconButton
              color="normal"
              className={clsx({
                [classes.icon__btn]: true
              })}
              onClick={() => {
                onClose && onClose();
              }}
            >
              <Close />
            </CCIconButton>
          </Box>
          <Box className={classes.content}>{contents}</Box>
        </>
      }
      classes={{ actions: classes.actions, ...customClasses }}
      {...any}
    />
  );
};
export default Dialog;
