import { allCountries } from "components/InputPhoneNumber";
import { reduce, startsWith } from "lodash";

const hasLowerCase = str => {
  return str.toUpperCase() != str;
};

const hasNumber = str => {
  return /\d/.test(str);
};

const validManageName = name => {
  const stringLength = name.length;

  if (stringLength < 4 || stringLength > 20) {
    return false;
  }

  if (!hasLowerCase(name)) {
    return false;
  }

  if (!hasNumber(name)) {
    return false;
  }

  return true;
};

export const validateEmptyString = value => Boolean((value || "").trim());

export const validatePhoneNumber = value => {
  const code = (value || "")
    .trim()
    .replace(/ *\([^)]*\) */g, "")
    .replace("+", "");

  return Boolean(value) && !allCountries.find(item => item.dialCode === code);
};

const guessSelectedCountry = (inputNumber, countries) => {
  const bestGuess = reduce(
    countries,
    (selectedCountry, country) => {
      if (startsWith(inputNumber, country.dialCode)) {
        if (country.dialCode.length > selectedCountry.dialCode.length) {
          return country;
        }
        if (
          country.dialCode.length === selectedCountry.dialCode.length &&
          country.priority < selectedCountry.priority
        ) {
          return country;
        }
      }
      return selectedCountry;
    },
    { dialCode: "", priority: 10001 }
  );

  return bestGuess;
};

export const getPhoneNumberWithoutDialCode = value => {
  if (!value) return "";
  const country = guessSelectedCountry(
    value.replace(/\D/g, "").substring(0, 6),
    allCountries
  );
  const dialCode = country?.dialCode ?? "";
  return value.replace(`+${dialCode}`, "");
};

export const validatePhoneNumberRequired = value => {
  if (!value) return false;
  const country = guessSelectedCountry(
    value.replace(/\D/g, "").substring(0, 6),
    allCountries
  );
  const dialCode = country?.dialCode ?? "";
  if (value.replace("+", "") === dialCode) return false;

  return true;
};

export const validatePhoneNumberByLength = value => {
  if (!value) return true;
  // Phone number must be between 9 and 11 characters​
  const country = guessSelectedCountry(
    value.replace(/\D/g, "").substring(0, 6),
    allCountries
  );
  const dialCode = country?.dialCode ?? "";
  if (value.replace("+", "") === dialCode) return true;
  const withoutDialCode = value.replace(/\D/g, "").replace(dialCode, "");

  return (
    Boolean(value) &&
    withoutDialCode.length >= 9 &&
    withoutDialCode.length <= 11
  );
};

export { validManageName };

