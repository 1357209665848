import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  LinearProgress,
  capitalize
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { CVMenu, CVTable, TimerWithRefetch, translate } from "components";
import moment from "moment";
import { useReducer, useState } from "react";
import { useIntl } from "react-intl";
import {
  CCButton,
  CCDialogContent,
  CCDialogSimple,
  CCDialogTitle,
  CCTypography
} from "styles/components";
import { RefreshIcon } from "styles/icons";
import { makeIndexFormattedMessageList } from "types";
import {
  BASE_USER_STATUS,
  USER_STATUS_TYPES,
  WITHDRAW_TYPES
} from "types/userManage";
import { StatisticBox } from "views/Normal/ApplicationManagement/components";
import {
  LabSearchModal,
  UserDetail,
  UserFilter,
  WithdrawModal
} from "./components";
// import { GENDER_TYPES } from "types/staff";

import { enqueueToast } from "components/Toast";
import { useSnackbar } from "notistack";
import {
  GET_LAB_LIST,
  GET_LAB_STATS,
  TOGGLE_RECOMMENDED_LAB,
  UPDATE_ACCOUNT_TYPE_LAB,
  UPDATE_LAB_STATUS
} from "queries/customer";
import { DEFAULT_DATE_TIME_FORMAT, LIMIT_PAGINATION } from "types/constants";
import { ACCOUNT_TYPE_VALUES } from "types/registrationManage";
import UserDownload from "./components/UserDownload/UserDownload";

const LIMIT = LIMIT_PAGINATION;
const INITIAL_FILTER = {
  // start: moment().startOf("month").unix(),
  // end: moment().endOf("month").unix(),
  searchText: "",
  limit: LIMIT,
  page: 1,
  status: 9,
  accountType: "lab"
};

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    backgroundColor: theme.palette.common.white
  },
  timer: {
    position: "absolute",
    zIndex: 1200,
    top: 18,
    right: 32,
    display: "flex"
  },
  timer__divider: {
    height: 36,
    borderLeft: "3px solid rgba(0, 0, 0, 0.16)",
    margin: "0 16px"
  },
  paper: { height: "100%", width: "100%" },
  content: { height: "calc(100% - 55px)", overflow: "hidden" },
  container: { height: "100%" },
  color__red: {
    color: "red"
  },
  color__green: {
    color: "green"
  },
  item: { width: "100%" },
  container__filter: {
    width: "100%",
    padding: "4px 12px",
    margin: "0px",
    alignItems: "center",
    borderTop: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`
  },
  input: { backgroundColor: theme.palette.common.white },
  title__field: { width: 243 },
  item__table: {
    marginBottom: theme.spacing(1),
    "margin-left": "-7%",
    height: "54vh"
  },
  table: {
    height: "100%",
    textAlign: "center",

    "& .MuiTypography-colorInherit": {
      color: theme.palette.input.main
    }
  },
  table__body: {
    backgroundColor: "rgba(207, 216, 220, 0.87)",
    "& > div:nth-child(even)": {
      background: theme.palette.common.white
    }
  },
  table__cell: {
    padding: "6px 4px"
  },
  toolbar__divider: {
    borderLeft: `1px solid ${theme.palette.border.main}`,
    height: 36,
    marginRight: 8
  },
  ml8: {
    marginLeft: 8
  },
  container__status: {
    position: "relative",
    display: "flex",
    borderTop: `1px solid ${theme.palette.border.main}`,
    padding: "28px 12px"
  },
  lab__search: {
    position: "absolute",
    right: 16,
    bottom: 16
  },
  refresh__icon: {
    // marginRight: "3%",
    cursor: "pointer",
    color: "blue"
  },
  inuse: {
    color: theme.palette.primary.main
  },
  withdraw: {
    color: theme.palette.error.main
  },
  discontinued: {
    // color: theme.palette.border
    color: "#f50057"
  },
  start_button: {
    color: "rgba(0, 0, 0, 0.38)",
    cursor: "pointer"
  },
  is_recommended: {
    color: "#ffab00"
  },
  statusDate__text: {
    color: "black"
  }
}));

const initializer = {
  filter: INITIAL_FILTER,
  withdrawModalVisible: false,
  labSearchModalVisible: false,
  successWithdrawDialogContent: false,
  checkedList: {},
  user: {}
};

const reducer = (state, action) => {
  switch (action.type) {
    case "detail": {
      return {
        ...state,
        user: action.target
      };
    }
    case "update":
      return {
        ...state,
        user: action.target
      };
    case "filter":
      console.log("action.target", action.target);
      return {
        ...state,
        filter: action.target
      };
    case "setCheckedList":
      return {
        ...state,
        checkedList: action.target
      };
    case "setWithDrawModal":
      return {
        ...state,
        withdrawModalVisible: action.target
      };
    case "setLabSearchModal":
      return {
        ...state,
        labSearchModalVisible: action.target
      };
    case "setSuccessWithdrawDialog":
      return {
        ...state,
        successWithdrawDialogContent: action.target
      };
    case "setDetailDialog": {
      // console.log('11111111111', action.target)
      return {
        ...state,
        isOpenDetailDialog: action.target
      };
    }
    case "reset":
      return { ...initializer };
    default:
      break;
  }

  return state;
};

const UserManagement = () => {
  const classes = useStyles();
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);
  // const [isOpenDetailDialog, setIsOpenDetailDialog] = useState(false);

  const { IN_USE, DISCONTINUED, WITH_DRAW } = BASE_USER_STATUS;

  const [state, dispatchState] = useReducer(reducer, initializer);

  const { enqueueSnackbar } = useSnackbar();

  const {
    filter,
    withdrawModalVisible,
    checkedList,
    labSearchModalVisible,
    successWithdrawDialogContent,
    user,
    isOpenDetailDialog
  } = state;

  const { data: dataStatistics, refetch: refetchStatistics } =
    useQuery(GET_LAB_STATS);

  const {
    data: dataLabList,
    loading,
    error,
    refetch: refetchLabList
  } = useQuery(GET_LAB_LIST, {
    variables: {
      input: INITIAL_FILTER
    }
  });

  const [updateLabStatus] = useMutation(UPDATE_LAB_STATUS, {
    onCompleted: () => {
      refetchStatistics();
      refetchLabList();
    }
  });

  const [updateLabAccountType] = useMutation(UPDATE_ACCOUNT_TYPE_LAB, {
    onCompleted: () => {
      refetchStatistics();
      refetchLabList();
    }
  });

  const [toggleIsRecommendedLab] = useMutation(TOGGLE_RECOMMENDED_LAB, {
    onCompleted: () => {
      refetchLabList();
    }
  });

  const customerStatusIndex = makeIndexFormattedMessageList(USER_STATUS_TYPES);

  const totalPages = Math.ceil(dataLabList?.labList.total / LIMIT);

  const handleClickMoreVert = event => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Create toast message to display when change status
   * @param {String} newStatus
   * @param {String} labName
   * @returns {String}
   */
  const createToastMessage = (newStatus, labName = "") => {
    let toastMessage = "";
    newStatus = String(newStatus);

    if (newStatus === IN_USE) {
      toastMessage = `${labName} status is changed to in use. ​`;
    }

    if (newStatus === DISCONTINUED) {
      toastMessage = `${labName} status is changed to temporary suspension. ​​`;
    }

    return toastMessage;
  };

  /**
   * Open toast when action success
   * @param {String} message
   */
  const openToast = (message = "") => {
    enqueueToast(enqueueSnackbar, message);
  };

  const handleChangeAccountType = userData => {
    updateLabAccountType({
      variables: {
        input: {
          status: Number(userData.status),
          ids: [userData.id],
          accountType: userData.accountType
        }
      }
    }).then(() => {
      openToast(
        translate(intl, "accountType.msg.switchedTo", {
          targetAccountType: capitalize(userData.accountType)
        })
      );
    });
  };

  const changeStatus = e => {
    const newStatus = String(e.target.value);

    const { id, labName } = user;

    const toastMessage = createToastMessage(newStatus, labName);

    updateLabStatus({
      variables: {
        input: {
          status: Number(newStatus),
          ids: [id]
          // withdrawDate: Number(withdrawType)
        }
      }
    }).then(() => {
      openToast(toastMessage);
      // refetchData();
    });
  };

  const handleSave = () => {
    const { id, status, labName } = user;
    const toastMessage = createToastMessage(status, labName);

    updateLabStatus({
      variables: {
        input: {
          status: Number(status),
          ids: [id]
          // withdrawDate: Number(withdrawType)
        }
      }
    }).then(rs => {
      openToast(toastMessage);
      // refetchData();
    });
    handleCloseDetailDialog();
  };

  const handleOnChangePagination = (event, value) => {
    dispatchState({
      type: "filter",
      target: { ...filter, page: value }
    });

    refetchLabList({
      input: {
        ...filter,
        accountType:
          filter.accountType === ACCOUNT_TYPE_VALUES.ALL.value
            ? undefined
            : filter.accountType,
        page: value
      }
    });
  };

  const handleCloseDetailDialog = () => {
    // setIsOpenDetailDialog(false);
    dispatchState({ type: "setDetailDialog", target: false });
  };

  const handleOpenDetailDialog = () => {
    dispatchState({ type: "setDetailDialog", target: true });
  };

  const statistics = [
    {
      title: "Total",
      color: "black",
      data: dataStatistics && dataStatistics.labStatistics.totalApproval
    },
    {
      title: "In Use",
      color: "green",
      data: dataStatistics && dataStatistics.labStatistics.totalInuse
    },
    {
      title: "Temporary Suspended",
      color: "red",
      data: dataStatistics && dataStatistics.labStatistics.totalDiscontinued
    }
    // {
    //   title: "Withdrawn",
    //   color: "gray",
    //   data: dataStatistics && dataStatistics.labStatistics.totalWithdraw
    // }
  ];

  const reformatStatus = (rowData = {}) => {
    const { status, withdrawDateTime } = rowData;

    if (status === parseInt(USER_STATUS_TYPES.IN_USE.value)) {
      return customerStatusIndex[status];
    }

    if (status === parseInt(USER_STATUS_TYPES.DISCONTINUED.value)) {
      return customerStatusIndex[status];
    }

    if (status === parseInt(USER_STATUS_TYPES.WITHDRAW.value)) {
      if (withdrawDateTime <= moment().unix()) {
        return customerStatusIndex[status];
      }
      return `Withdraw`;
    }

    // if (
    //   status === parseInt(USER_STATUS_TYPES.WITH_DRAW_AFTER_ONE_MONTH.value)
    // ) {
    //   if (withdrawDateTime <= moment().unix()) {
    //     return customerStatusIndex[status];
    //   }

    //   return `Withdraw after 1m (${moment
    //     .unix(withdrawDateTime)
    //     .format("YYYY.MM.DD")})`;
    // }
  };

  const reformatMoreVert = () => {
    let { status } = user;
    status = String(status);

    let menuItemList = [];

    if (status == WITH_DRAW) {
      return;
    }

    if (status === IN_USE) {
      menuItemList = [
        {
          value: DISCONTINUED,
          color: "red",
          label: "Temporary Suspension",
          onClick: changeStatus
        }
      ];
    }

    if (status === DISCONTINUED) {
      menuItemList = [
        {
          value: IN_USE,
          color: "green",
          label: "In Use",
          onClick: changeStatus
        }
      ];
    }

    const menuStatusItemList = (
      <CVMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        menuItems={menuItemList}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        elevation={1}
      />
    );

    return menuStatusItemList;
  };

  const heads = [
    {
      width: 100,
      className: classes.table__cell,
      component: ({ rowData }) => {
        const checked = !!checkedList[rowData.id];

        return (
          <Checkbox
            checked={checked}
            style={{ padding: 0 }}
            disableRipple
            onChange={() => {
              if (checked) {
                dispatchState({
                  type: "setCheckedList",
                  target: { ...checkedList, [rowData.id]: false }
                });
              } else {
                dispatchState({
                  type: "setCheckedList",
                  target: { ...checkedList, [rowData.id]: true }
                });
              }
            }}
          />
        );
      }
    },
    { key: "labId", label: "Lab ID", className: classes.table__cell },
    {
      key: "labName",
      label: "Lab Name",
      className: classes.table__cell
    },
    {
      key: "labTel",
      label: "Tel No.",
      className: classes.table__cell,
      component: ({ cellData }) => {
        return (
          <CCTypography variant="body1">
            {cellData ? cellData : ""}
          </CCTypography>
        );
      }
    },
    {
      key: "accountType",
      label: translate(intl, "common.ui.accountType"),
      className: classes.table__cell,
      component: ({ cellData }) => {
        return capitalize(cellData);
      }
    },
    {
      key: "registrationAt",
      label: "Registration Approved Date",
      className: classes.table__cell,
      component: ({ rowData, cellData }) => {
        return (
          <CCTypography variant="body1">
            {moment.unix(cellData).format(DEFAULT_DATE_TIME_FORMAT)}
          </CCTypography>
        );
      }
    },
    {
      key: "status",
      label: "Usage Status",
      className: classes.table__cell,
      component: ({ cellData, rowData }) => {
        return (
          <>
            <CCTypography
              variant="body1"
              className={clsx({
                [classes.inuse]:
                  USER_STATUS_TYPES.IN_USE.value === String(cellData),
                [classes.discontinued]:
                  USER_STATUS_TYPES.DISCONTINUED.value === String(cellData),
                [classes.withdraw]:
                  USER_STATUS_TYPES.WITHDRAW.value === String(cellData)
              })}
            >
              {reformatStatus(rowData)}{" "}
              <span className={classes.statusDate__text}>
                (
                {moment
                  .unix(rowData.statusUpdatedAt)
                  .format(DEFAULT_DATE_TIME_FORMAT)}
                )
              </span>
              {/* {customerStatusIndex[cellData]} */}
            </CCTypography>

            {/* {customerStatusIndex[cellData]} */}
          </>
        );
      }
    },
    {
      width: 100,
      className: classes.table__cell,
      component: ({ rowData }) => {
        return (
          <Grid>
            <MoreVert onClick={handleClickMoreVert} />
            {reformatMoreVert()}
          </Grid>
        );
      }
    }
  ];

  const handleOnSearch = value => {
    dispatchState({
      type: "filter",
      target: { ...value, status: Number(value.status), page: 1 }
    });
    refetchLabList({
      input: {
        limit: LIMIT,
        searchText: value.searchText,
        start: value.start,
        end: value.end,
        status: Number(value.status),
        accountType:
          value.accountType === ACCOUNT_TYPE_VALUES.ALL.value
            ? undefined
            : value.accountType
      }
    });
  };

  const updateStatusEventEmitsEvent = user => {
    dispatchState({
      type: "update",
      target: user
    });
  };

  const onUpdate = user => {
    dispatchState({
      type: "update",
      target: user
    });

    const { id, status, labName } = user;
    const toastMessage = createToastMessage(status, labName);

    updateLabStatus({
      variables: {
        input: {
          status: Number(status),
          ids: [id]
          // withdrawDate: Number(withdrawType)
        }
      }
    }).then(rs => {
      openToast(toastMessage);
      // refetchData();
    });
    handleCloseDetailDialog();
  };

  const filterSelectedCustomer = () => {
    return Object.keys(checkedList).filter(key => checkedList[key]);
  };

  const changeCustomerStatus = (
    status = USER_STATUS_TYPES.IN_USE.value,
    withdrawType = WITHDRAW_TYPES.NOW.value
  ) => {
    console.log("changeCustomerStatus", withdrawType);
    const selectedCustomers = filterSelectedCustomer();
    if (!selectedCustomers.length) return;
    updateLabStatus({
      variables: {
        input: {
          status: Number(status),
          ids: selectedCustomers,
          withdrawDate: Number(withdrawType)
        }
      }
    });
  };

  const refetchData = () => {
    dispatchState({
      type: "reset"
    });
    refetchStatistics();
    refetchLabList({
      input: INITIAL_FILTER
    });
  };

  if (error) return <div>Some thing went wrong!</div>;

  if (loading) return <LinearProgress color="secondary" />;

  return (
    <>
      <Box className={classes.timer}>
        <TimerWithRefetch refetch={refetchData} />
        <div className={classes.timer__divider}></div>
      </Box>
      <Box className={classes.root}>
        <Box className={classes.paper}>
          <CCDialogTitle>User Management</CCDialogTitle>
          <CCDialogContent className={classes.content} noPadding>
            <Grid
              container
              direction={"column"}
              wrap={"nowrap"}
              className={classes.container}
            >
              <Grid item className={classes.container__status}>
                <StatisticBox statistics={statistics} />
              </Grid>

              <Grid
                className={classes.container__filter}
                container
                justify="space-between"
              >
                <Grid>
                  <Grid container justify="flex-start" alignItems="center">
                    <UserFilter filter={filter} onSearch={handleOnSearch} />
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container>
                    <UserDownload
                      filename="User Manage"
                      filter={{
                        ...filter,
                        page: 1,
                        accountType:
                          filter.accountType === ACCOUNT_TYPE_VALUES.ALL.value
                            ? undefined
                            : filter.accountType,
                        limit: dataLabList?.labList?.total || 0
                      }}
                    />
                    <Button
                      className={classes.refresh__icon}
                      onClick={refetchData}
                    >
                      <RefreshIcon fontSize="large" color="secondary" />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={classes.item__table} item>
                <CVTable
                  onRowClick={({ data, rowIndex, key }) => {
                    dispatchState({
                      type: "detail",
                      target: { ...data, currentStatus: data.status }
                    });

                    if (key !== undefined) {
                      handleOpenDetailDialog(true);
                    }
                    // console.log(
                    //   "onRowClick.data",
                    //   data,
                    //   "onRowClick.rowIndex",
                    //   rowIndex,
                    //   "key",
                    //   key
                    // );
                  }}
                  heads={heads}
                  contents={dataLabList && dataLabList.labList.items}
                  classes={{ table__body: classes.table__body }}
                  className={classes.table}
                />
              </Grid>

              <Grid container item justifyContent="center" direction="column">
                <Pagination
                  count={totalPages}
                  page={filter.page}
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                  onChange={handleOnChangePagination}
                  showFirstButton
                  showLastButton
                />
              </Grid>
            </Grid>
          </CCDialogContent>
        </Box>

        {withdrawModalVisible && (
          <WithdrawModal
            open={withdrawModalVisible}
            onClose={() =>
              dispatchState({
                type: "setWithDrawModal",
                target: false
              })
            }
            onDisagree={() =>
              dispatchState({
                type: "setWithDrawModal",
                target: false
              })
            }
            onAgree={withdrawType => {
              let content = "Successfully withdrawn.";

              // if (withdrawType === WITHDRAW_TYPES.AFTER_ONE_MONTH.value) {
              //   content = "Reserved for withdraw after 1 month";

              //   changeCustomerStatus(
              //     USER_STATUS_TYPES.WITH_DRAW_AFTER_ONE_MONTH.value,
              //     withdrawType
              //   );
              // }

              if (withdrawType === WITHDRAW_TYPES.NOW.value) {
                changeCustomerStatus(
                  USER_STATUS_TYPES.WITHDRAW.value,
                  withdrawType
                );
              }

              dispatchState({
                type: "setWithDrawModal",
                target: false
              });
              dispatchState({
                type: "setSuccessWithdrawDialog",
                target: content
              });
            }}
          />
        )}

        {!!successWithdrawDialogContent && (
          <CCDialogSimple
            open={!!successWithdrawDialogContent}
            contents={
              <CCTypography variant="body1" color="error">
                {successWithdrawDialogContent}
              </CCTypography>
            }
            endActions={
              <CCButton
                variant="contained"
                color="error"
                onClick={() => {
                  dispatchState({
                    type: "setSuccessWithdrawDialog",
                    target: ""
                  });
                }}
              >
                Yes
              </CCButton>
            }
            onClose={() =>
              dispatchState({
                type: "setSuccessWithdrawDialog",
                target: ""
              })
            }
          />
        )}

        {labSearchModalVisible && (
          <LabSearchModal
            open={labSearchModalVisible}
            onClose={() =>
              dispatchState({
                type: "setLabSearchModal",
                target: false
              })
            }
          />
        )}

        <CCDialogSimple
          className={classes.createLab_dialog}
          title={"Details"}
          open={isOpenDetailDialog}
          // open={false}
          maxWidth={"md"}
          onClose={handleCloseDetailDialog}
          contents={
            <UserDetail
              data={{ ...user }}
              onUpdate={onUpdate}
              onChangeAccountType={handleChangeAccountType}
            />
          }
          onCloseButton={handleCloseDetailDialog}
          // endActions={
          //   <>
          //     <CCButton onClick={handleCloseDetailDialog}>Cancel</CCButton>
          //     <CCButton
          //       onClick={handleSave}
          //       startIcon={<CheckIcon />}
          //       variant="contained"
          //     >
          //       Save
          //     </CCButton>
          //   </>
          // }
        ></CCDialogSimple>
      </Box>
    </>
  );
};

export default UserManagement;
